import { gql } from "@apollo/client";
import { createContext } from "react";
import { MutationUpdateUserArgs, User } from "../types/graphql";
import { useMe } from "./useMe.hook";

export const MeContext = createContext<{
  me: User;
  setMe: (o: { variables: MutationUpdateUserArgs }) => Promise<any>;
  refreshMe: () => Promise<any>;
}>(null);

const QUERY = gql`
  query index {
    me {
      _id
      username
      name
      image
      articles {
        _id
        title
      }
      following {
        _id
        name
        image
        username
        articleOfTheWeek {
          title
          published
          shortText
          slug
          isDeleted
        }
      }
      peopleToFollow {
        _id
        name
        image
        username
        articleOfTheWeek {
          title
          published
          shortText
          slug
          isDeleted
        }
      }
      articleOfTheWeek {
        title
        published
        shortText
        slug
        isDeleted
      }
    }
  }
`;

export default function Me({ children }) {
  const [me, setMe, refreshMe] = useMe(QUERY);
  return (
    <MeContext.Provider value={{ me, setMe, refreshMe }}>
      {children}
    </MeContext.Provider>
  );
}
