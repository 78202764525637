import "../styles/globals.css";

import { ApolloProvider } from "@apollo/client";
import { Analytics } from "@vercel/analytics/react";
import { SessionProvider } from "next-auth/react";
import client from "../apollo-client";
import Me from "../hooks/context";

export default function App({ Component, pageProps }) {
  return (
    <ApolloProvider client={client}>
      <SessionProvider session={pageProps.session}>
        <Me>
          <Component {...pageProps} />
          <Analytics />
        </Me>
      </SessionProvider>
    </ApolloProvider>
  );
}
