import { DocumentNode, gql, useLazyQuery, useMutation } from "@apollo/client";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { MutationUpdateUserArgs, User } from "../types/graphql";

const MUTATION = gql`
  mutation UpdateMe($input: UserUpdate!) {
    updateUser(input: $input) {
      following {
        _id
      }
      image
    }
  }
`;

export function useMe(
  query: DocumentNode
): [
  User,
  (o: { variables: MutationUpdateUserArgs }) => Promise<any>,
  () => Promise<any>
] {
  const { data: session, status } = useSession();
  const [loadData, { data: { me } = { me: null } }] = useLazyQuery<{
    me: User;
  }>(query, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    loadData();
  }, [session]);
  const [updateUser] = useMutation<{ user: User }, MutationUpdateUserArgs>(
    MUTATION,
    {
      refetchQueries: [{ query }, "index"],
    }
  );
  async function temp() {
    await loadData();
  }
  return [me, updateUser, temp];
}
